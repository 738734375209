.main-container {
  width: 100%;
  max-height: 700px;
  margin-top: 40px;
  overflow: scroll;
  overflow-x: hidden;
}
.main-container::-webkit-scrollbar{
  display: none;
}
