.App {
  text-align: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  margin-right: 20px;
}
.App::-webkit-scrollbar{
  display: none;
}
a {
  color: #61dafb;
}
