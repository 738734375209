.about-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  position: relative;
}

.intro {
  color: white;
  font-size: 15px;
  display: block;
  text-align: left;
  margin-top: 100px;
  font-weight: bold;
}
.renova {
  color: white;
  font-size: 120px;
  display: block;
  text-align: left;
  font-weight: bolder;
}
.dd {
  color: #61dafb;
  font-size: 15px;
  display: block;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}
.front-end {
  color: #6e7a8e;
  font-size: 12px;
  display: block;
  text-align: left;
  margin-top: 0px;
  font-weight: bold;
}
.hire-me {
  width: 200px;
  height: 40px;
  float: left;
  margin-top: 25px;
  background-color: #61dafb;
  border: 1px solid #61dafb;
  color: #1e2431;
  font-weight: bolder;
  border-radius: 5px;
}
.hire-me:hover {
  width: 200px;
  height: 40px;
  float: left;
  margin-top: 25px;
  background-color: #1e2431;
  border: 1px solid #61dafb;
  color: #61dafb;
  font-weight: bold;
}
.socials {
  display: block;
  color: #61dafb;
  margin-top: 100px;
}
.social {
  float: left;
  font-size: 20px;
  margin-right: 100px;
}
.me {
  height: 300px;
  position: absolute;
  top: 50px;
  left: 700px;
  border: 2px solid white;
}
