.card {
  width: 300px;
  height: 330px;
  background-color: #61dafb;
  float: left;
  margin-right: 35px;
  margin-bottom: 35px;
  position: relative;
  border: 1px solid transparent;
}
.card:hover {
  background-color: #181e2b;
  border: 1px solid white;
}
.card:hover .stacks {
  color: white;
}
.card:hover .card-text-container .card-text {
  color: white;
}
.card:hover .icons .card-icon {
  color: white;
}
.icons {
  width: 100%;
  height: 30px;
  margin-top: 20px;
}
.card-icon {
  font-size: 20px;
  float: right;
  margin-right: 15px;
  font-weight: bolder;
  color: #181e2b;
}
.stacks {
  position: absolute;
  bottom: 25px;
  color: #181e2b;
  font-weight: bold;
  font-size: 12px;
}
.card-text {
  display: block;
  text-align: left;
  margin-left: 50px;
  font-size: 35px;
  color: #181e2b;
  font-weight: bold;
}
.card-text-container {
  margin-top: 100px;
}
.card a {
  color: black;
}
.card:hover a {
  color: #61dafb;
}
