.side-container {
  width: 75px;
  height: 97%;
  background-color: #1e2431;
  position: relative;
}
.side-icon {
  font-size: 35px;
  margin-top: 10px;
}
.icon-container {
  width: 100%;
  height: 62px;
}
.settings {
  position: absolute;
  bottom: 0px;
}
.github {
  position: absolute;
  bottom: 80px;
  color: #61dafb;
}
.active {
  border-left: 2px solid #61dafb;
}
.changes {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #61dafb;
  color: #1e2431;
  position: absolute;
  right: 12px;
  top: 145px;
  font-size: 15px;
  font-weight: bold;
  align-items: center;
}
