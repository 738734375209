.path-container{
    height: 20px;
    width: 100%;
}
.path{
    float: left;
    font-size: 16px;
    margin-left: 7px;
}
.path:hover{
    color: yellow;
}
.path-icon{
    float: left;
    font-size: 20px;
    margin-left: 7px;
}
.class-icon{
    color: orange;
    float: left;
    height: 100%;
    vertical-align: middle;
    line-height:40px;
}
.icon-jsn{
    color: yellowgreen;
    height: 100%;
    vertical-align: middle;
    line-height:40px;
}