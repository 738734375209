.page-area-wrapper {
  width: 100%;
  height: 100%;
  background-color: #1e2431;
  position: absolute;
  display: flex;
  min-width: 1000px;
}
.side-icons{
  height: 100%;
  background-color: aquamarine;
}