.gitignore-menu-container{
    margin-top: 5px;
    float: left;
   width: 100%;
}
.gitignore-menu-container:hover{
    background-color:#181e2b;
}
.gitignore-menu-container:active{
    border: 1px solid gray;
}
.gitignore-icon{
    float: left;
    margin-left: 20px;
    margin-top: 3px;
    color: steelblue;

}
.gitignore-text{
    font-size: 17px;
    float: left;
    margin-left: 10px;
}
.git-logo{
    width: 15px;
    height: 15px;
    float: left;
    margin-left: 20px;
}