.menusc-container{
    margin-top: 5px;
    float: left;
   width: 100%;
}
.menusc-container:hover{
    background-color:#181e2b;
}
.menusc-icon{
    float: left;
    color:steelblue;
    font-weight: bolder;
    font-size: 12;
    margin-left: 20px;
    margin-top: 3px;

}
.menusc-name{
    font-size: 17px;
    float: left;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
}