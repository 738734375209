.explorer-menu-container{
    width: 100%;
    height: 35px;
}
.explorer-text{
    float: left;
    margin-left: 10px;
    margin-top: 15px;
}
.explorer-icon{
    float: right; 
    height: 100%;
    background-color:#181e2b;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    
}
.explorer-icon:hover{
    background-color:#293142;
    
}