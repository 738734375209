.explorer-container {
  left: 80px;
  width: 320px;
  height: 98%;
  background-color: #1e2431;
  padding: 5px;
}
.src-menu-contents {
  margin-left: 12px;
}
