body {
  color: #6e7a8e;
}
.footer-container {
  width: 100%;
  height: 30px;
  background-color: #1e2431;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  min-width: 1000px;
}
.footer-icon {
  font-size: 20px;
  color: #6e7a8e;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}
.footer-icon:hover {
  background-color: #181e2b;
}
.footer-div-contents {
  height: 100%;
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
  background-color: #181e2b;
  align-content: center;
  position: absolute;
  left: 12px;
}
footer-text-container-div:hover {
  background-color: darkkhaki;
}
.footer-text-container-div {
  height: 100%;
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
  position: absolute;
  left: 35px;
}
.footer-text {
  height: 100%;
  color: #6e7a8e;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}
.footer-text:hover {
  background-color: #181e2b;
}
.upload-icon {
  position: absolute;
  left: 150px;
}
.error-icon {
  position: absolute;
  left: 200px;
}
.warning-icon {
  position: absolute;
  left: 240px;
}
.bell-icon {
  position: absolute;
  right: 12px;
}
.feedback-icon {
  position: absolute;
  right: 50px;
}
.javascript-react {
  position: absolute;
  height: 100%;
  right: 100px;
  font-size: 17px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}
.javascript-react:hover {
  background-color: #181e2b;
}
.lf {
  position: absolute;
  right: 240px;
  height: 100%;
  font-size: 17px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}
.lf:hover {
  background-color: #181e2b;
}
.utf-8 {
  position: absolute;
  right: 280px;
  height: 100%;
  font-size: 17px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}
.utf-8:hover {
  background-color: #181e2b;
}
.spaces4 {
  position: absolute;
  right: 350px;
  height: 100%;
  font-size: 17px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}
.spaces4:hover {
  background-color: #181e2b;
}
.ln32 {
  position: absolute;
  right: 455px;
  height: 100%;
  font-size: 17px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}
.ln32:hover {
  background-color: #181e2b;
}
